'use client';

import { ParsedForm } from '@/lib/parsers/form';
import { useEffect } from 'react';
import createStore, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { applyFieldConditions } from './fieldConditions';
import { FieldValue, FieldValueType, FormState } from './formTypes';

/**
 * Create the form context state
 */
export const createFormStore = (mutationName: string, form: ParsedForm) => {
  const { formFields, settings } = form;

  return createStore<FormState>((set) => ({
    mutationName,
    values: {},
    fieldStatuses: {},
    errors: {},
    isLoading: false,
    isSuccess: false,
    isError: false,
    messages: {
      error: settings?.errorMessageHtml ?? 'An error occurred',
      success: settings?.submitActionMessageHtml ?? 'Submitted',
    },
    setValue: (name, value) => {
      set(({ values }) => {
        const newValues = { ...values, [name]: value };
        const newFieldStatuses = applyFieldConditions(formFields, newValues);

        return {
          values: newValues,
          fieldStatuses: newFieldStatuses,
        };
      });
    },
    unsetValue: (name) => {
      set(({ values }) => {
        delete values[name];
        return { values };
      });
    },
    setErrors: (errors) => set({ errors }),
    setValues: (values) => set({ values }),
    setIsSuccess: (isSuccess) => set({ isSuccess }),
    setIsLoading: (isLoading) => set({ isLoading }),
    setIsError: (isError) => set({ isError }),
    /* submit: async () => {
      const { values, mutationName } = get();

      const hasValues = !!keys(values).length;

      if (!hasValues) return;

      set({
        isSuccess: false,
        isLoading: true,
        isError: false,
      });

      submitForm(mutationName, values).then((res) => {
        const errors = parseFormSubmissionErrors(res);
        const maybeResult =
          (res as FetchResult<GenericFormSubmissionMutation> | null)?.data?.submission ?? null;
        const result = !errors && maybeResult ? maybeResult : null;
        const isSuccess = !!result;

        const newValues = isSuccess ? {} : values;

        set({
          isSuccess: isSuccess,
          isLoading: false,
          isError: !!errors,
          errors: errors ?? {},
          values: newValues,
        });
      });
    }, */
  }));
};

// ------------------------------------------------------------------------------------------------
// ---- Hooks ----

export const { Provider: FormContextProvider, useStore: useForm } =
  createContext<StoreApi<FormState>>();

export const getFieldDefault = <T extends FieldValueType = 'single'>(
  type?: T
  // defaultValue?: FieldValue<T>
) => (type === 'complex' ? {} : type === 'multi' ? [] : '') as FieldValue<T>;

/**
 * Retrieve and set the value for a given field in the {@link FormState}
 */
export const useFormField = <T extends FieldValueType>(
  name: string,
  type?: T,
  defaultValue?: FieldValue<T>
) => {
  const { value, setValue, unsetValue, errors } = useForm((state) => ({
    value: state.values[name],
    setValue: (value: FieldValue<T> | null) => state.setValue(name, value),
    unsetValue: () => state.unsetValue(name),
    errors: (state.errors ?? {})[name],
  }));

  useEffect(() => {
    setValue(defaultValue ?? getFieldDefault(type));

    return () => unsetValue();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    value: (value ?? getFieldDefault(type)) as FieldValue<T>,
    setValue,
    unsetValue,
    errors,
    error: !!errors,
  };
};
