import { rawColorValues } from '@/theme/partials/palette.css';
import { vars } from '@/theme/vars.css';
import { OutlinedTextFieldProps, TextField } from '@mui/material';
import * as styles from './Fields.css';

export type DefaultTextFieldProps = Partial<OutlinedTextFieldProps>;

export const DefaultTextField = (props: DefaultTextFieldProps) => (
  <TextField
    variant="filled"
    fullWidth
    sx={{ rowGap: vars.spacing.xs }}
    InputLabelProps={{
      focused: false,
      sx: {
        position: 'relative',
        transform: 'none',
        paddingBottom: 'xs',
        color: vars.palette.colorSets.full.contrastText,
      },
    }}
    InputProps={{
      sx: { p: 0 },
      disableUnderline: true,
      fullWidth: true,
    }}
    inputProps={{
      className: styles.placeholder,
      sx: {
        padding: vars.spacing.sm,
        backgroundColor: rawColorValues.white,
        color: rawColorValues.black,
        border: '1px solid #000000',
      },
    }}
    {...props}
  />
);
