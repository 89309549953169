import Icon from '@/components/Icon';
import Txt from '@/components/ui/Txt';
import { Collapse, CollapseProps, List, ListItem, ListItemIcon } from '@mui/material';
import { FieldErrors as FieldErrorsType } from '../formTypes';

export type FieldErrorsProps = {
  errors?: FieldErrorsType;
} & CollapseProps;

export const FieldErrors = ({ errors = [], ...props }: FieldErrorsProps) => {
  return (
    <Collapse in={!!errors.length} unmountOnExit {...props}>
      <List component="ul" disablePadding sx={{ marginY: 1 }}>
        {errors.map((v, i) => (
          <ListItem key={i} disableGutters>
            <ListItemIcon sx={{ minWidth: 25 }}>
              <Icon name="error" color="error" />
            </ListItemIcon>
            {/* <ListItemText>{v}</ListItemText> */}
            <Txt variant="small" as="span">
              {v}
            </Txt>
          </ListItem>
        ))}
      </List>
    </Collapse>
  );
};
