'use client';

import { GenericFormSubmissionMutation } from '@/__generated__/graphql';
import { FormErrors } from '@/components/Formie/formTypes';
import { parseFormSubmissionErrors, parseSubmitFormQuery } from '@/lib/parsers/form';
import { FetchResult, isApolloError, useApolloClient } from '@apollo/client';
import { useCallback } from 'react';
import { useForm } from './useForm';

export default function useFormSubmit() {
  const client = useApolloClient();
  const mutationName = useForm((s) => s.mutationName);
  const formValues = useForm((s) => s.values);

  const setIsSuccess = useForm((s) => s.setIsSuccess);
  const setIsLoading = useForm((s) => s.setIsLoading);
  const setIsError = useForm((s) => s.setIsError);
  const setErrors = useForm((s) => s.setErrors);
  const setValues = useForm((s) => s.setValues);

  const submit = useCallback(() => {
    const hasValues = !!Object.keys(formValues).length;
    if (!hasValues) return;
    setIsSuccess(false);
    setIsLoading(true);
    setIsError(false);
    const mutation = parseSubmitFormQuery({ mutationName, formValues });

    client
      .mutate({ mutation })
      .then((res) => {
        const errors = parseFormSubmissionErrors(res);
        const maybeResult =
          (res as FetchResult<GenericFormSubmissionMutation> | null)?.data?.submission ?? null;
        const result = !errors && maybeResult ? maybeResult : null;
        const isSuccess = !!result;

        const newValues = isSuccess ? {} : formValues;
        console.log({
          res,
          isSuccess,
          errors,
        });

        setIsSuccess(isSuccess);
        setIsLoading(false);
        setIsError(!!errors);
        setErrors(errors ?? {});
        setValues(newValues);
      })
      .catch((error) => {
        setIsError(true);
        setIsLoading(false);

        if (isApolloError(error)) {
          const { graphQLErrors = [] } = error;

          const formErrors: FormErrors = graphQLErrors.reduce((results, item) => {
            const { message } = item;
            const parsedMessage = JSON.parse(message) as FormErrors;
            return {
              ...results,
              ...parsedMessage,
            };
          }, {} as FormErrors);

          setErrors(formErrors);
        } else {
          setErrors({ error: ['An error occurred.'] });
          console.error(error);
        }
      });
  }, [
    formValues,
    setIsSuccess,
    setIsLoading,
    setIsError,
    mutationName,
    client,
    setErrors,
    setValues,
  ]);

  return { triggerSubmit: submit };
}
