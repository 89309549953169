import { createField } from '@/lib/parsers/form';
import { useFormField } from '@/components/Formie/useForm';
import dayjs from 'dayjs';
import { DefaultTextField, DefaultTextFieldProps } from './DefaultTextField';
import { FieldErrors } from './FieldErrors';

export const Field_Text = createField(({ field, ..._props }) => {
  const {
    // defaultValue: _defaultValue,
    handle,
    name,
    placeholder,
    required,
    instructions,
  } = field;

  // const _defaultValue = maybeGet(field, 'defaultValue');

  const { value, setValue, error, errors } = useFormField(handle);

  // Modify loaded value here
  switch (field.__typename) {
    case 'Field_Date':
      break;
  }

  const handleChange = (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const valueRaw = ev.target.value;
    let newValue = valueRaw;

    // Modify value before saving
    switch (field.__typename) {
      case 'Field_Date':
        // console.log('toISOString', dayjs(valueRaw).toISOString());
        if (valueRaw) newValue = `${valueRaw}T00:00:00.000Z`;
        break;
    }

    setValue(newValue);
  };

  const fieldProps: DefaultTextFieldProps = {
    value,
    error,
    'name': handle,
    'helperText': instructions,
    'label': name,
    placeholder,
    required,
    'onChange': handleChange,
    'fullWidth': true,
    'aria-describedby': `${handle}-field-error`,
  };

  switch (field.__typename) {
    case 'Field_MultiLineText':
      fieldProps.multiline = true;
      fieldProps.minRows = 3;
      break;

    case 'Field_Email':
      fieldProps.type = 'email';
      fieldProps.value = value ?? 'email';
      break;

    case 'Field_Number':
      fieldProps.type = 'number';
      break;

    case 'Field_Phone':
      fieldProps.type = 'tel';
      break;

    case 'Field_Date':
      fieldProps.type = 'date';
      fieldProps.InputLabelProps = { ...fieldProps.InputLabelProps, shrink: true };
      if (value && typeof value === 'string') fieldProps.value = dayjs(value).format('YYYY-MM-DD');
      break;
  }

  return (
    <>
      <DefaultTextField {...fieldProps} />
      <FieldErrors id={`${handle}-field-error`} errors={errors} />
    </>
  );
});
