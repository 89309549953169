import { createField } from '@/lib/parsers/form';
import { useFormField } from '@/components/Formie/useForm';
import { SelectChangeEvent } from '@mui/material';
import { DefaultSelectField } from './DefaultSelectField';
import { FieldErrors } from './FieldErrors';

export const Field_Dropdown = createField<'Field_Dropdown'>(({ field }) => {
  const { options, multi, defaultValue, handle, name, placeholder, required, instructions } = field;

  const { value, setValue, error, errors } = useFormField(handle, multi ? 'multi' : 'single');

  const handleChange = (ev: SelectChangeEvent<unknown>) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setValue(ev.target.value as any);
  };

  return (
    <>
      <DefaultSelectField
        {...{
          'SelectProps': {
            multiple: multi,
            value,
            onChange: handleChange,
          },
          value,
          error,
          'name': handle,
          'defaultValue': multi ? [] : defaultValue,
          'helperText': instructions,
          'label': name,
          placeholder,
          required,
          options,
          'aria-describedby': `${handle}-field-error`,
        }}
      />
      <FieldErrors id={`${handle}-field-error`} errors={errors} />
    </>
  );
});
