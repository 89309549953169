import { makeNonNullableArray } from '@liquorice/allsorts-craftcms-nextjs';
import { FieldErrors } from './FieldErrors';
import { createField } from '@/lib/parsers/form';
import { useFormField } from '../useForm';
import { Checkbox, FormControl, FormControlLabel, FormHelperText, FormLabel } from '@mui/material';

export const Field_Agree = createField<'Field_Agree'>(({ field, ..._props }) => {
  const {
    name,
    handle,
    defaultState,
    instructions,
    description,
    required,
    // checkedValue,
    labelPosition,
    // uncheckedValue,
  } = field;

  // use 1/0 string as value for conditional fields compatibility
  const checkedValue = '1';
  const uncheckedValue = '0';

  const { value, setValue, error, errors } = useFormField(
    handle,
    'single',
    defaultState ? checkedValue : uncheckedValue
  );

  const label =
    makeNonNullableArray(JSON.parse(description ?? ''))[0].content[0].text ?? name ?? '';

  const handleChange = (_ev: React.SyntheticEvent<Element, Event>, checked: boolean) => {
    setValue(checked ? checkedValue : uncheckedValue);
  };

  return (
    <FormControl required={required} error={error}>
      <FormLabel component="legend" sx={{ mb: 1 }} hidden={labelPosition === 'hidden'}>
        {name}
      </FormLabel>
      <FormControlLabel
        {...{
          'sx': { mb: 1 },
          'aria-describedby': `${handle}-field-error`,
          'onChange': handleChange,
          label,
          'control': <Checkbox checked={value === checkedValue} />,
        }}
      />
      <FormHelperText>{instructions}</FormHelperText>
      <FieldErrors id={`${handle}-field-error`} errors={errors} />
    </FormControl>
  );
});
