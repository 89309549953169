'use client';

import useFormSubmit from '@/components/Formie/useFormSubmit';
import { ParsedForm } from '@/lib/parsers/form';
import { useCallback } from 'react';
import { FormieFields } from './FormieFields';
import { FormieSubmitRow } from './FormieSubmitRow';
import { createFormStore, FormContextProvider } from './useForm';

export type FormieProps = React.PropsWithChildren<{
  form?: ParsedForm;
}>;

const FormieForm = ({ form, children }: FormieProps) => {
  const { formFields: fields = [] } = form ?? {};

  const submit = useFormSubmit().triggerSubmit;

  const handleSubmit = useCallback(
    (ev: React.FormEvent) => {
      ev.preventDefault();
      submit();
    },
    [submit]
  );

  return (
    <form noValidate onSubmit={handleSubmit}>
      <FormieFields fields={fields} />
      {children}
      <FormieSubmitRow />
    </form>
  );
};

const Formie = ({ form, children }: FormieProps) => {
  const mutationName = form?.submissionMutationName;

  return mutationName ? (
    <FormContextProvider createStore={() => createFormStore(mutationName, form)}>
      <FormieForm form={form}>{children}</FormieForm>
    </FormContextProvider>
  ) : (
    <></>
  );
};

export default Formie;
