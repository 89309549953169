import { createField } from '@/lib/parsers/form';
import { useFormField } from '@/components/Formie/useForm';
import { Box, BoxProps, FormControl, FormHelperText, FormLabel, Stack } from '@mui/material';
import { DefaultSelectField } from './DefaultSelectField';
import { DefaultTextField } from './DefaultTextField';
import { FieldErrors } from './FieldErrors';
import { Field_Text } from './Field_Text';

const Field_NameSingle = createField<'Field_Name'>((props) => {
  return <Field_Text {...props} />;
});

const Field_NameMulti = createField<'Field_Name'>(({ field, ..._props }) => {
  const {
    // firstNameCollapsed,
    firstNameEnabled,
    firstNameErrorMessage,
    firstNameLabel,
    firstNamePlaceholder,
    // firstNamePrePopulate,
    firstNameRequired,
    //
    // middleNameCollapsed,
    middleNameEnabled,
    middleNameErrorMessage,
    middleNameLabel,
    middleNamePlaceholder,
    // middleNamePrePopulate,
    middleNameRequired,
    //
    // lastNameCollapsed,
    lastNameEnabled,
    lastNameErrorMessage,
    lastNameLabel,
    lastNamePlaceholder,
    // lastNamePrePopulate,
    lastNameRequired,
    //
    // prefixCollapsed,
    prefixEnabled,
    prefixErrorMessage,
    prefixLabel,
    prefixOptions,
    prefixPlaceholder,
    // prefixPrePopulate,
    prefixRequired,
    //
    firstNameDefaultValue,
    middleNameDefaultValue,
    lastNameDefaultValue,
    prefixDefaultValue,
    //

    handle,
    name,
    instructions,
    labelPosition,
  } = field;

  const { value, setValue, error, errors } = useFormField(handle, 'complex', {
    ...(firstNameEnabled && { firstName: firstNameDefaultValue ?? '' }),
    ...(middleNameEnabled && { middleName: middleNameDefaultValue ?? '' }),
    ...(lastNameEnabled && { lastName: lastNameDefaultValue ?? '' }),
    ...(prefixEnabled && { prefix: prefixDefaultValue ?? '' }),
  });

  const handleChange =
    (fieldName: string) => (ev: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setValue({ ...value, [fieldName]: ev.target.value });
    };

  const fieldWrapProps: BoxProps = {
    minWidth: 100,
    maxWidth: '50%',
  };

  return (
    <FormControl error={error} fullWidth>
      <FormLabel component="legend" sx={{ mb: 2 }} hidden={labelPosition === 'hidden'}>
        {name}
      </FormLabel>
      {instructions && <FormHelperText>{instructions}</FormHelperText>}

      <Stack direction="row" columnGap={2} rowGap={4} flexWrap="wrap">
        {prefixEnabled && (
          <Box flexGrow={2} {...fieldWrapProps}>
            <DefaultSelectField
              {...{
                'name': 'prefix',
                'onChange': handleChange('prefix'),
                'value': value['prefix'] ?? '',
                'helperText': prefixErrorMessage,
                // hidden: prefixHidden,
                'label': prefixLabel,
                'placeholder': prefixPlaceholder,
                // prefixPrePopulate,
                'required': prefixRequired,
                'options': prefixOptions,
                'aria-describedby': `${handle}-field-error`,
              }}
            />
          </Box>
        )}
        {firstNameEnabled && (
          <Box flexGrow={3} {...fieldWrapProps}>
            <DefaultTextField
              {...{
                'name': 'firstName',
                'onChange': handleChange('firstName'),
                'value': value['firstName'] ?? 'given-name',
                'helperText': firstNameErrorMessage,
                // hidden: firstNameHidden,
                'label': firstNameLabel,
                'placeholder': firstNamePlaceholder,
                // firstNamePrePopulate,
                'required': firstNameRequired,
                'aria-describedby': `${handle}-field-error`,
              }}
            />
          </Box>
        )}
        {middleNameEnabled && (
          <Box flexGrow={3} {...fieldWrapProps}>
            <DefaultTextField
              {...{
                'name': 'middleName',
                'onChange': handleChange('middleName'),
                'value': value['middleName'] ?? 'additional-name',
                'helperText': middleNameErrorMessage,
                // hidden: middleNameHidden,
                'label': middleNameLabel,
                'placeholder': middleNamePlaceholder,
                // middleNamePrePopulate,
                'required': middleNameRequired,
                'aria-describedby': `${handle}-field-error`,
              }}
            />
          </Box>
        )}
        {lastNameEnabled && (
          <Box flexGrow={3} {...fieldWrapProps}>
            <DefaultTextField
              {...{
                'name': 'lastName',
                'onChange': handleChange('lastName'),
                'value': value['lastName'] ?? 'family-name',
                'helperText': lastNameErrorMessage,
                // hidden: lastNameHidden,
                'label': lastNameLabel,
                'placeholder': lastNamePlaceholder,
                // lastNamePrePopulate,
                'required': lastNameRequired,
                'aria-describedby': `${handle}-field-error`,
              }}
            />
          </Box>
        )}
      </Stack>

      {errors && <FieldErrors id={`${handle}-field-error`} errors={errors} />}
    </FormControl>
  );
});

export const Field_Name = createField<'Field_Name'>((props) => {
  const { field } = props;

  const { useMultipleFields } = field;

  return useMultipleFields ? <Field_NameMulti {...props} /> : <Field_NameSingle {...props} />;
});
